import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/coupon/develop'


const getDevelopData =(params)=>getAction(baseURL+"/getData",params);


export{
    getDevelopData,
}
