<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
  >
    <el-row type="flex" class="row-bg" justify="space-around">
      <el-col :span="7">
        <div class="grid-content">
          <el-form label-width="80px" :model="saveUpdateFrom">
            <el-form-item label="货币名称" prop="name">
              <el-input v-model="saveUpdateFrom.name" disabled />
            </el-form-item>
            <el-form-item label="货币代码" prop="code">
              <el-input v-model="saveUpdateFrom.code" disabled />
            </el-form-item>
            <el-form-item label="汇率" prop="exchangeRate">
              <el-input v-model="saveUpdateFrom.exchangeRate" />
            </el-form-item>
          </el-form></div
      ></el-col>
      <el-col :span="4"
        ><div class="grid-content-ight">
          <el-button
            type="success"
            class="grid-content-ight-button"
            round
            size="medium"
            style="margin: 50% 50%"
            @click="getExchangeButton"
            >确定</el-button
          >
          <br />
          <!-- <el-button
            type="success"
            class="grid-content-ight-button"
            round
            size="medium"
            style="margin: -30px auto"
            >成功按钮</el-button
          > -->
        </div></el-col
      >
      <el-col :span="7">
        <div class="grid-content">
          <el-form label-width="80px" :model="targetFrom">
            <el-form-item label="货币名称" prop="targetFromName">
              <el-select
                v-model="targetFromName"
                filterable
                placeholder="请选择"
                style="width: 80%"
                value-key="id"
                @change="getCurrency"
              >
                <el-option
                  v-for="item in currencyList"
                  :key="item.code"
                  :label="item.name"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="货币代码" prop="code">
              <el-input v-model="targetFrom.code" disabled></el-input>
            </el-form-item>
          </el-form></div
      ></el-col>
    </el-row>
    <div v-if="targetDataShow">
      <el-descriptions class="margin-top" :title="saveTitle" :column="2">
        <template slot="extra">
          <el-button type="primary" size="small" @click="adoptResults"
            >采纳</el-button
          >
        </template>
        <el-descriptions-item label="更新时间">{{
          this.saveTime
        }}</el-descriptions-item>
        <el-descriptions-item label="当前汇率">{{
          this.saveRate
        }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div v-if="targetDataShow">
      <el-descriptions class="margin-top" :title="targetTitle" :column="2">
        <el-descriptions-item label="更新时间">{{
          this.targerTime
        }}</el-descriptions-item>
        <el-descriptions-item label="当前汇率">{{
          this.targerRate
        }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div v-if="targetDataShow">
      <h2 style="color: red">
        温馨提示:实时货币汇率查询换算，数据仅供参考，交易时以银行柜台成交价为准。
      </h2>
    </div>

    <div class="container">
      <div class="box">
        <el-button type="primary" @click="editSaveButton">保 存</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </div>

    <!-- </el-dialog> -->
  </el-dialog>
</template>
    <script>
import format from "@/plugins/format.js";
import {
  getCommonCurrencySelectByIdApi,
  editCommonCurrencyDataApi,
  getCommonCurrencListApi,
} from "@/api/system/currency/currency.js";

import { getDevelopData } from "@/api/coupon/develop.js";

export default {
  data() {
    return {
      targetFromName: {},
      targetFrom: {},
      currencyList: [],
      dialogVisible: false,
      ids: null,
      types: 1,
      editShow: true,
      title: null,
      saveUpdateFrom: {},
      // 表单校验
      rules: {},
      oldExchangeRate: null,
      saveTitle: null,
      targetTitle: null,
      targetDataShow: false,

      saveTime: null,
      saveRate: null,
      targerTime: null,
      targerRate: null,
    };
  },
  methods: {
    //打开弹窗 1新增 2编辑
    show(row, type) {
      this.initData(row);
      this.dialogVisible = true;
      this.types = type;
      this.title = "编辑";
      this.ids = row.id;
      this.selectDataById();
    },
    selectDataById() {
      getCommonCurrencySelectByIdApi(this.ids).then((res) => {
        this.saveUpdateFrom = res.result;
        this.oldExchangeRate = res.result.exchangeRate;
      });
    },
    initData(row) {
      getCommonCurrencListApi({ notCode: row.code }).then((res) => {
        this.currencyList = res.result;
      });
    },
    cancel() {
      (this.saveTitle = null), (this.targetTitle = null);
      this.saveUpdateFrom = {};
      this.targetFrom = {};
      this.dialogVisible = false;
      //调用父组件的查询
      this.$parent.selectListButton();
      this.ids = null;
      this.$parent.id = null;

      this.$parent.questionnaireId = null;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.cancel();
        })
        .catch((_) => {});
    },
    editSaveButton() {
      this.saveUpdateFrom.exchangeRateTime = format(
        new Date(),
        "YYYY-MM-DD HH:mm:ss"
      );
      editCommonCurrencyDataApi(this.saveUpdateFrom).then((res) => {
        this.cancel();
        this.$parent.selectListButton();
      });
    },
    getCurrency(val) {
      this.targetFrom = val;
    },
    getExchangeButton() {
      this.targetDataShow = true;
      var targerName = this.targetFrom.name;
      var targerCode = this.targetFrom.code;
      console.log(targerCode);

      var saveName = this.saveUpdateFrom.name;
      var saveCode = this.saveUpdateFrom.code;

      this.targetTitle = `货币：${targerName}(${targerCode}) --> 货币：${saveName}(${saveCode})`;
      this.saveTitle = `货币：${saveName}(${saveCode}) --> 货币：${targerName}(${targerCode})`;

      var dataResp = {
        from: this.saveUpdateFrom.code,
        to: this.targetFrom.code,
        //这里上线要调整
        menuCode: "xunCommon-U0FI-IF-1",
      };
      getDevelopData(dataResp).then((res) => {
        console.log(res.result.result);
        if (null != res.result.result) {
          if (res.result.result[0].currencyF == this.saveUpdateFrom.code) {
            this.saveTime = res.result.result[0].updateTime;
            this.saveRate = res.result.result[0].exchange;
            this.targerTime = res.result.result[1].updateTime;
            this.targerRate = res.result.result[1].exchange;
          } else {
            this.saveTime = res.result.result[1].updateTime;
            this.saveRate = res.result.result[1].exchange;
            this.targerTime = res.result.result[0].updateTime;
            this.targerRate = res.result.result[0].exchange;
          }
        }
      });
    },
    adoptResults() {
      this.saveUpdateFrom.exchangeRate = this.saveRate;
    },
  },
};
</script>
 <style lang="less" scoped>
.grid-content {
  height: 100px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.grid-content-ight {
  height: 100px;
}

.grid-content-ight-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-buttons {
  display: inline;
  float: right;
  height: 100px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  margin-left: auto;
}
</style>